
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class AboutMyManagement extends Vue {
        // Data function
        public data() {
            return {
                managerIconPathAndName:
                    require("@/assets/images/icon_manager.png"),
            };
        }
    }
